import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, zip } from "rxjs";
import { environment } from "../../../../environments/environment";
import { AuthenticationService, NewsService } from "../../../core";
import { HeaderImage } from "../../../core/models/products.model";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { Authors } from "../../models/authors.model";
import { MoreNews } from "../../models/more-news.model";
import { NewsTopics } from "../../models/news-topics.model";
import { ImageDTO, NewsDetail, NewsSection } from "../../models/news.model";
import { Tags } from "../../models/tags.model";
import { I18nService } from "../../services";
import { DOCUMENT, DatePipe } from "@angular/common";
import { MetaService } from "@ngx-meta/core";
import { MetaTagService } from "../../services/meta-tag.service";
import { Directions } from "../../../core/models/enums/direction.enum";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  shareSocialMedia,
  SocialMediaProviders,
  textFromHtml
} from "../../../core/_helpers/share-helper";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { StaticImagesSEOService } from "../../../core/services/static-images.service";
import { ImageSEO } from "../../../core/models/image-seo.model";
import { title } from "process";
import { NewsNavigateService } from "../../services/news.navigate.service";
import { DemoDialogService } from "../../../core/services/dialog";
import { DemoDialogComponent } from "../dialogs";
import { action, page } from "../../utils/params";

@Component({
  selector: "app-detail-news",
  templateUrl: "./detail-news.component.html",
  styleUrls: ["./detail-news.component.scss"]
})
export class DetailNewsComponent implements OnInit, AfterViewInit {
  @ViewChild("mainDiv") mainDiv: ElementRef;
  @ViewChild("slickCarousel", { static: true })
  carousel: SlickCarouselComponent;
  currentCode: string = "";
  news: NewsDetail = new NewsDetail();
  mostRead: MoreNews[] = [];
  topics: NewsTopics[] = [];
  isExpanded: boolean = false;
  isMobile = false;
  idNews;
  sectionImages: ImageDTO[];
  currentIndex = 0;
  headerImgCaption: string;
  browserLang: any;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1
  };

  socialMediaLinks: any = [];

  defaultSlide: any;
  siilaBrandSEO: ImageSEO;
  spotTransformingSEO: ImageSEO;
  siilaAcademyBannerSEO: ImageSEO;
  trustedUrl: SafeResourceUrl;
  unsafeUrl = "https://player.vimeo.com/video/822080532"; // Reemplaza con tu URL
  show = true;
  translatedPrimaryContent: string;
  public $isLoggedIn: Observable<boolean>;

  constructor(
    private newsService: NewsService,
    private i18: I18nService,
    private _cdr: ChangeDetectorRef,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private siilaTranslationService: SiilaTranslationService,
    private datePipe: DatePipe,
    private metaSrv: MetaTagService,
    public sanitizer: DomSanitizer,
    private staticImgSrv: StaticImagesSEOService,
    @Inject(DOCUMENT) private document: any,
    private newsNavigate: NewsNavigateService,
    private demo: DemoDialogService,
    private auth: AuthenticationService
  ) {
    this.currentCode = environment.platformId;
    this.breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.defaultSlide = {
      arrows: true,
      infinite: false
    };

    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.unsafeUrl
    );
    this.$isLoggedIn = this.auth.$isLoginSubject;
  }
  ngAfterViewInit() {}

  ngOnInit() {
    const whatsappSEO = this.staticImgSrv.getShareWhatsappSEO();
    const shareEmailSEO = this.staticImgSrv.getShareEmailSEO();
    const facebookSEO = this.staticImgSrv.getShareFacebookSEO();
    const linkedSEO = this.staticImgSrv.getShareLinkedinSEO();
    this.socialMediaLinks = [
      {
        alt: whatsappSEO.alt,
        title: whatsappSEO.title,
        img: "../../../assets/icons/whatsapp.png",
        providerId: "whatsapp",
        link: this.document.location.href,
        options: {
          title: null
        }
      },
      {
        alt: shareEmailSEO.alt,
        title: shareEmailSEO.title,
        img: "../../../assets/icons/mail.png",
        providerId: "email",
        link: "mailto:",
        options: {
          mailTo: "",
          subject: null,
          body: null
        }
      },
      {
        alt: facebookSEO.alt,
        title: facebookSEO.title,
        img: "../../../assets/icons/facebook.png",
        providerId: "facebook",
        link:
          this.currentCode == "br"
            ? "https://web.facebook.com/SiiLA.BR/"
            : "https://www.facebook.com/SiiLA.mx",
        options: {
          title: null
        }
      },
      {
        alt: linkedSEO.alt,
        title: linkedSEO.title,
        img: "../../../assets/icons/in.png",
        providerId: "linkedin",
        link:
          this.currentCode == "br"
            ? "https://www.linkedin.com/company/siila-br"
            : "https://www.linkedin.com/company/siila-mx",
        options: {
          title: null
        }
      }
    ];
    this.siilaBrandSEO = this.staticImgSrv.getMultifamilySEO();
    this.spotTransformingSEO = this.staticImgSrv.getSpotTransformingExperienceSEO();
    this.siilaAcademyBannerSEO = this.staticImgSrv.getAcademyImgSEO();
    this.route.params.subscribe(params => {
      this.idNews = params.id;
      this.getNews();
      this.getTopics();
    });

    this.newsService.changesLang$.subscribe(val => {
      if (val) {
        this.browserLang = this.siilaTranslationService.getCurrentLanguage();
        this.openNews(
          this.news.id,
          this.siilaTranslationService.getTranslation(this.news.headline),
          true
        );
      }
    });
  }

  getNews() {
    this.translatedPrimaryContent = "";
    zip(
      this.newsService.getNewsId(this.idNews),
      this.newsService.getMostNews()
    ).subscribe(resp => {
      this.news = resp[0] as NewsDetail;
      if (this.news) {
        this.translatedPrimaryContent = this.siilaTranslationService.getTranslation(
          this.news.primaryContent
        );
      }
      this.validateURL();
      this.news.sectionsInfo.map(x =>
        x.videoURL != null && x.videoURL != ""
          ? (x.videoURL = this.sanitizeUrl(x.videoURL))
          : ""
      );

      if (this.news.headerImageCaption) {
        this.headerImgCaption = this.siilaTranslationService.getTranslation(
          this.news.headerImageCaption
        );
      }
      if (this.news.sectionsInfo) {
        try {
          this.sectionImages = this.news.sectionsInfo.map(
            (ns: NewsSection) => ns.sectionImgsRES
          )[0];
        } catch (error) {}
      }
      this.metaSrv.setInsightsDetailTags(this.news);
      this.mostRead = resp[1] as MoreNews[];
      setTimeout(() => {
        this.addBlanktoLinks();
      }, 1000);
    });
  }

  validateURL() {
    let currentURL = window.location.href.split("/");
    if (currentURL.length >= 5) {
      currentURL[3] =
        this.siilaTranslationService.getCurrentLanguage() == "en"
          ? "news"
          : "noticias";
      currentURL[4] = this.news.urlTitle
        ? this.refactorTitle(this.news.urlTitle)
        : this.refactorTitle(this.news.headline);
      const url = currentURL.join("/");
      history.pushState(null, url, url);
    }
  }

  refactorTitle(title) {
    return this.siilaTranslationService
      .getTranslation(title)
      .toLowerCase()
      .replaceAll(/[ %?]/g, "-")
      .replaceAll(/['"()<>&\\\/]/g, "");
  }

  addBlanktoLinks() {
    const links = this.mainDiv.nativeElement.querySelectorAll("a");
    links.forEach(link => {
      link.setAttribute("target", "_blank");
    });
  }

  getTopics() {
    this.newsService.getNewsTopics().subscribe(resp => {
      this.topics = resp as NewsTopics[];
    });
  }

  headerImageNews(item: string, size = false): string {
    const url = item.replace("***", size ? "1280w" : "640w");
    return `${environment.cloudfrontURL}${url}`;
  }
  playButton(isExpanded: boolean) {
    this.isExpanded = !isExpanded;
  }

  openNews(id: number, title, reload = false) {
    this.newsNavigate.openNews(
      id,
      title.urlTitle ? title.urlTitle : title.headline,
      this.isMobile || reload,
      "",
      false,
      this.browserLang
    );
  }

  getTranslation(value: string) {
    const r = JSON.parse(value)[
      this.siilaTranslationService.getCurrentLanguage()
    ];
    return r;
  }

  getLanguage() {
    return this.siilaTranslationService.getCurrentLanguage();
  }

  formatDate(date, format) {
    return this.datePipe.transform(date, format);
  }

  openSite() {
    window.open(
      this.currentCode == "br"
        ? "https://siila.com.br" + `/lang/${this.i18.getCurrentLanguage()}`
        : "https://siila.com.mx" + `/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openLatam() {
    window.open(
      "https://siila.com/lang/en",
      this.isMobile ? "_self" : "_blank"
    );
  }

  goNewsTags(tag, color, type = "tag") {
    window.open(
      `resource/${type}/${this.siilaTranslationService.getTranslation(
        tag.name
      )}/${
        tag.id
      }/${color}/lang/${this.siilaTranslationService.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  goHome(latam = false) {
    window.open(
      latam
        ? "https://siila.com/resource" + `/lang/en`
        : `resource/lang/${this.i18.getCurrentLanguage()}`,
      "_self"
    );
  }

  isLastImage(): boolean {
    return this.currentIndex === this.sectionImages.length - 1;
  }

  isFirstImage(): boolean {
    return this.currentIndex === 0;
  }

  nextImage(): void {
    if (!this.isLastImage()) {
      this.currentIndex++;
    }
  }

  prevImage(): void {
    if (!this.isFirstImage()) {
      this.currentIndex--;
    }
  }

  prevSlide(carousel: any) {
    if (!carousel) {
      return;
    }
    carousel.slickPrev();
  }

  loadPage(direction: string, carousel: any) {
    switch (direction) {
      case Directions.Next:
        const lastSlideActive = this.isLastImage();

        if (!lastSlideActive) {
          carousel.slickNext();
          this._cdr.detectChanges();
        } else if (lastSlideActive) {
          carousel.slickNext();
        } else {
          //this.appendPage(Directions.Next, carousel);
        }
        break;
      case Directions.Back:
        this.prevSlide(carousel);
        break;
    }
  }

  shareLink(socialLink) {
    /* const articleTitle = this.getDefaultTranslation(this.article.title);
    const content = this.getDefaultTranslation(this.article.content); */

    switch (socialLink.providerId) {
      case SocialMediaProviders.Facebook:
      case SocialMediaProviders.LinkedIn:
        socialLink.options.body = textFromHtml(this.getDefaultTranslation(""));
        socialLink.options.url = window.location.href;
        socialLink.options.title = this.getDefaultTranslation(
          this.news.headline
        );
        shareSocialMedia(socialLink);
        break;
      case SocialMediaProviders.Email:
        const subject = `SiiLA News: ${this.getDefaultTranslation(
          this.news.headline
        )}`;
        const body =
          textFromHtml(
            `${this.getDefaultTranslation(this.news.primaryContent)}`
          ).substring(0, 300) +
          textFromHtml(
            ` ...\n\n<br><br><a href="${window.location.href}">${window.location.href}</a>`
          );
        const mailtoUrl = `mailto:?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoUrl;
        break;
      case SocialMediaProviders.Whatsapp:
        window.open(
          `https://api.whatsapp.com/send?text=${this.getDefaultTranslation(
            this.getDefaultTranslation(this.news.headline)
          )}%20${socialLink.link}`,
          "_blank"
        );
        break;
      default:
        break;
    }
  }

  getAltTxt(text: string) {
    return this.siilaTranslationService.getTranslation(text);
  }

  getDefaultTranslation(translationArtifact) {
    try {
      return this.siilaTranslationService.getTranslation(translationArtifact);
    } catch (e) {}
  }

  isEnglishLang() {
    return this.i18.getCurrentLanguage() === "en";
  }

  isBR() {
    return this.currentCode && this.currentCode.toLowerCase() === "br";
  }

  openSpot() {
    if (this.isBR()) {
      var url = this.isEnglishLang()
        ? "https://siilaspot.com.br/en"
        : "https://siilaspot.com.br/pt-br";
      window.open(url, this.isMobile ? "_self" : "_blank");
    } else {
      window.open(
        "/SPOT" + `/lang/${this.i18.getCurrentLanguage()}`,
        this.isMobile ? "_self" : "_blank"
      );
    }
  }

  openProduct() {
    window.open(
      "/marketanalytics" + `/lang/${this.i18.getCurrentLanguage()}`,
      this.isMobile ? "_self" : "_blank"
    );
  }

  openAcademy() {
    window.open(
      "https://mailchi.mp/siila/academy-advanced",
      this.isMobile ? "_self" : "_blank"
    );
  }

  openMultifamilyBanner() {
    let url = this.isEnglishLang()
      ? "https://mailchi.mp/siila.com.br/7gms21uue1"
      : "https://mailchi.mp/siila.com.br/multifamily";
    window.open(url, this.isMobile ? "_self" : "_blank");
  }

  sanitizeUrl(url): SafeResourceUrl {
    const trustedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    return trustedUrl;
  }

  isIOS(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  openContactDialog() {
    this.demo.open(
      DemoDialogComponent,
      false,
      page.resource,
      action.requestDemo,
      "",
      this.i18.get(`analytics.${this.currentCode}.analytic-list.titleModal`)
    );
  }

  login() {
    window.open(
      `${environment.redirectURL}?newscallback=${this.news.id}`,
      "_self"
    );
  }

  validateOtherTopics(topic) {
    if (topic == "OUTROS" || topic == "OTROS" || topic == "OTHER") {
      return false;
    }

    return true;
  }

  /**
   * @param  {} event
   */
  slickInit(event) {}
}
