import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { PropertyService } from "../../services/property.services";
import { ActivatedRoute, Router } from "@angular/router";
import { SiilaIndexReportComponent } from "../static-pages";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { ImageGalleryComponent } from "../image-gallery/image-gallery.component";
import { BreakpointObserver } from "@angular/cdk/layout";
import { SendNotificationComponent } from "../send-notification/send-notification.component";
import { generate, Observable, of, Subject, zip } from "rxjs";
import { AccountService, NewsService } from "../../../core";
import { NewsSearch } from "../../models/news-search.model";
import { NewsNavigateService } from "../../services/news.navigate.service";
import { SiilaTranslationService } from "../../../core/services/siila-translation.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexTheme
} from "ng-apexcharts";
import { apexObj, apexObj2 } from "../../utils/params";
import { DatePipe } from "@angular/common";
import { I18nService } from "../../services";
import { MetaService } from "@ngx-meta/core";
import { FeaturedListingService } from "../../../core/services/featured-listing.service";
import { DeviceRegistrationService } from "../../../core/services/device-registration.service";
import { ProductService } from "../../../core/services/products.service";
import { ProductItem } from "../../../core/models/products.model";
import { color } from "html2canvas/dist/types/css/types/color";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonGenericService } from "../../services/common.service";
import * as e from "express";
import {
  PropertyFeaturesService,
  PropertyOptions
} from "../../services/property-features.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocalNumberPipeSpot } from "../../pipes/number-pipe";
import { HttpClient } from "@angular/common/http";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  last,
  switchMap
} from "rxjs/operators";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  stroke: any;
  dataLabels: any;
  fill: ApexFill;
  tooltip: ApexTooltip;
  theme: ApexTheme;
  colors: string[];
  events: any;
  responsive: any;
};

const monthNamesMap = {
  Jan: "Ene",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Abr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic"
};

export type TenantAction = {
  id: number;
  buildingTenant: any;
  actionType: number;
  dateAction: Date;
  tenantNameEdit: string;
  marketSegment: any;
  newTenantArea: any;
  askingRentMXN: number;
  askingRentUSD: number;
};

export type BuildingFeatureDetails = {
  featureName: string;
  featureDisplayName: string;
  featureSelectedOptions: string[];
};

export type PropertyFeaturesIds = {
  id: number;
  buildingId: number;
  featureId: number;
  selectedOptions: number[];
  active: boolean;
};

export type UnitMix = {
  floorPlan: string;
  numberOfUnits: number;
  avgArea: number;
  cqAvgMonthlyRent: number;
  monthlyrentsm: number;
  cqAvgMonthlyRentFurnished: number;
  monthlyrentsmf: number;
  detailsName: string;
};

export type MarketSegment = {
  id: number;
  name: string;
};

export type BuildingTenantConfig = {
  building: any;
  id: number;
  quarter: number;
  year: number;
  status: string;
  removedFromStats: boolean;
};

export type BuildingTenantComment = {
  configId: number;
  comment: string;
  createdDate: Date;
  user: any;
};

@Component({
  selector: "app-tenants-property",
  templateUrl: "./tenants-property.component.html",
  styleUrls: ["./tenants-property.component.scss"]
})
export class TenantsPropertyComponent implements OnInit {
  dialogRef: any;
  id;
  isMobile: boolean = false;
  listingCards;
  property: any;
  photos: any[] = [];
  registry: any[] = [];
  plan: any[] = [];
  documents: any[] = [];
  buildings = [];
  propertiesComparable;
  platformId = environment.platformId;
  owners;
  developers;
  isAdmin = false;
  isResearchAdmin = false;
  isResearcher = false;
  user: any;
  articles: NewsSearch;
  browserLang;
  showDetail = false;
  vacantAreaList: any = [];
  countBomaArea = false;
  marketRentList: any = [];
  occupancies: any = [];
  tenantsLastQuarter: any = [];
  marketSegments: Array<MarketSegment> = [];
  historicOccupancies: any = [];
  countBomaMarket = [];
  industryPercentagesList: any = [];
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsOccupancy: Partial<ChartOptions>;
  public chartOptionsPie: Partial<ChartOptions>;
  tenantAreas: [] = [];
  showShortDesciption = true;
  hiddeMoreFeatures;
  pointOfInterest = [];
  vacancySpot: any;
  typeBar = 1;
  marketCardList = [];
  marketImgCard: string[];
  products: ProductItem[];
  currentCode: string;
  listFeatures: any = [];
  listManager: any = [];
  unitsMix: any = [];
  unitMixColVisibility: Map<string, boolean> = new Map();
  featuresOptions: BuildingFeatureDetails[] = [];
  validateMarketRent = [];
  showPhone = false;
  showEmail = false;
  rentRolls = [];
  currentFundsBuilding = [];
  neighborhoodDescription;
  customPropertyFeatures: PropertyOptions[] = [];
  keyFactsMultifamilyOpts: PropertyOptions[] = [];
  areasMeasurementsOpts: PropertyOptions[] = [];
  complexInformation = null;
  countPlannedDate = 0;
  buildingOccupancies: any;
  selectedTenant: any;
  currentAction: any;
  confirmationDialogMode: number = 0;
  editMoveOutDate: Date;
  editAskingRentMXN: number;
  editAskingRentUSD: number;
  newTenantArea: number = 0;
  splitSuite: boolean = false;
  moveInSameQuarter: boolean = false;
  config: BuildingTenantConfig;
  comments: BuildingTenantComment[];

  editTenantWithIndustry: any;
  selectedMarketSegment: MarketSegment;
  editTenantName: string = "";
  editIndustryName: string = "";
  autocompleteResults: Array<any> = [];
  isLoading: boolean = false;
  showAddTenants: boolean = false;
  addTenantMode: boolean = false;
  newComment: string = "";
  showNewCommentBox: boolean = false;
  rejectionMode: boolean = false;
  private searchSubject = new Subject<string>();
  private mxnInputSubject = new Subject<string>();
  private usdInputSubject = new Subject<string>();

  @ViewChild("chartModal", { static: false }) chartModal!: ElementRef;

  @ViewChild("confirmationDialog", { static: false })
  confirmationDialog!: TemplateRef<any>;

  @ViewChild("commentsDialog", { static: false })
  commentsDialog!: TemplateRef<any>;

  get isBomaMarket() {
    return this.countBomaMarket && this.countBomaMarket.length > 0;
  }

  constructor(
    private propertyService: PropertyService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private newsService: NewsService,
    private newsNavigate: NewsNavigateService,
    private siilaTranslationService: SiilaTranslationService,
    private datePipe: DatePipe,
    private i18: I18nService,
    private metaService: MetaService,
    private featureListingService: FeaturedListingService,
    private deviceRegistration: DeviceRegistrationService,
    private accountService: AccountService,
    private sanitizer: DomSanitizer,
    private productService: ProductService,
    private propertyFeaturesService: PropertyFeaturesService,
    private commonService: CommonGenericService,
    private modalService: NgbModal,
    private localNumberPipe: LocalNumberPipeSpot,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) {
    this.breakpointObserver
      .observe(["(max-width: 450px)"])
      .subscribe(result => {
        this.isMobile = result.matches;
      });
    this.browserLang = this.siilaTranslationService.getCurrentLanguage();
    this.chartOptions = apexObj as ChartOptions;
    this.chartOptionsOccupancy = apexObj2 as ChartOptions;
    this.marketImgCard = [
      "assets/img/products-list/indices.png",
      "assets/img/products-list/investors.png",
      "assets/img/products-list/grocs.png"
    ];
    this.currentCode = environment.platformId;
  }

  async ngOnInit() {
    if (this.validateSesion()) {
      this.route.params.subscribe(params => {
        this.id = params.id;
        this.propertyService.getPropertyId(this.id).then(resp => {
          this.property = resp;
          this.neighborhoodDescription = this.siilaTranslationService.getTranslation(
            this.property?.neighborhoodDescription
          );

          this.sanitizeDescription();
          this.metaService.setTitle(
            this.i18.getTranslation(this.property.title)
          );

          this.splitOwners(this.property.owners);
          this.splitDevelopers(this.property.developers);
          zip(
            this.propertyService.getLastQuarterTenantsInfo(this.id),
            this.propertyService.getMarketSegments()
          ).subscribe(async resp => {
            this.showDetail = true;

            this.tenantsLastQuarter = (resp[0] as any).buildingTenants;
            this.config = (resp[0] as any).config;
            this.comments = (resp[0] as any).comments;
            /* this.comments.push({
              configId: this.config.id,
              comment: "Comment #1 - Testing a little bit more text within the comment to see how the modal will expand.",
              createdDate: new Date(),
              user: { id: 1, firstName: "Pedro", lastName: "Pascal" }
            });
            this.comments.push({
              configId: this.config.id,
              comment: "Comment #2 - Testing a little bit more text within the comment to see how the modal will expand. This time even more data within the message... BYE",
              createdDate: new Date(),
              user: { id: 1, firstName: "Pedro", lastName: "Pascal" }
            }); */
            this.updateCheckboxValues(this.tenantsLastQuarter);
            this.marketSegments = resp[1] as MarketSegment[];
            this.marketSegments = this.marketSegments.sort((a, b) =>
              this.siilaTranslationService
                .getTranslation(a.name)
                .localeCompare(
                  this.siilaTranslationService.getTranslation(b.name)
                )
            );
          });
        });
      });
    } else {
      this.router.navigateByUrl("/");
    }

    this.mxnInputSubject.pipe(debounceTime(300)).subscribe(query => {
      if (query.length > 2 && this.editMoveOutDate) {
        this.editAskingRentMXN = parseFloat(query);
        this.propertyService
          .getExchangeRate(this.editMoveOutDate)
          .then((resp: any) => {
            if (resp.value) {
              this.editAskingRentUSD =
                Math.round((this.editAskingRentMXN / resp.value) * 100) / 100;
              // leave only 2 decimal places
              //this.editAskingRentUSD = Math.round(this.editAskingRentUSD * 100) / 100;
            }
          });
      }
    });

    this.usdInputSubject.pipe(debounceTime(300)).subscribe(query => {
      if (query.length > 2 && this.editMoveOutDate) {
        this.editAskingRentUSD = parseFloat(query);
        this.propertyService
          .getExchangeRate(this.editMoveOutDate)
          .then((resp: any) => {
            if (resp.value) {
              this.editAskingRentMXN =
                Math.round(this.editAskingRentUSD * resp.value * 100) / 100;
            }
          });
      }
    });

    this.searchSubject
      .pipe(
        debounceTime(300), // Wait 300 ms after typing stops
        distinctUntilChanged(), // Ignore duplicate queries
        switchMap(query => {
          this.editTenantWithIndustry = null;
          this.showAddTenants = false;
          if (query.length > 3) {
            this.isLoading = true;
            return this.propertyService
              .fetchTenantsAutocompleteResults(query)
              .pipe(
                catchError(error => {
                  console.error("Error fetching autocomplete results:", error);
                  this.isLoading = false; // Stop loading if error occurs
                  return of([]); // Return an empty array in case of error
                })
              );
          } else {
            this.isLoading = false;
            return of([]); // If input is too short, return an empty array
          }
        })
      )
      .subscribe(results => {
        this.isLoading = false; // Stop loading icon once results are received
        this.autocompleteResults = results; // Update dropdown with results
        this.autocompleteResults.push({
          tenantName:
            'Add "' + this.editTenantName.trim() + '" as a new tenant',
          industryName: this.editIndustryName
        });
        if (
          this.autocompleteResults.length == 0 &&
          this.editTenantName.length > 3
        ) {
          this.showAddTenants = true;
        }
      });
  }
  /* 
  private chartModalInstance!: Modal;
  ngAfterViewInit() {
    if (this.chartModal) {
      this.chartModalInstance = new Modal(this.chartModal.nativeElement);
    }
  } */

  onDateChange(newDate: string): void {
    if (newDate) {
      this.propertyService
        .getExchangeRate(this.editMoveOutDate)
        .then((resp: any) => {
          if (resp.value) {
            if (this.editAskingRentUSD) {
              this.editAskingRentMXN =
                Math.round(this.editAskingRentUSD * resp.value * 100) / 100;
            }
            if (this.editAskingRentMXN) {
              this.editAskingRentUSD =
                Math.round((this.editAskingRentMXN / resp.value) * 100) / 100;
            }
          }
        });
    }
  }

  disableManualEntry(event: KeyboardEvent): void {
    event.preventDefault();
  }

  formatDates(date: Date | string): string {
    if (!date) return "";

    var formatDate =
      this.i18.getCurrentLanguage() === "pt-br" ? "dd/MM/yyyy" : "MM/dd/yyyy";

    return this.datePipe.transform(date, formatDate) || "";
  }

  onAddTenant() {
    this.addTenantMode = true;
  }

  onBackToSearchTenant() {
    this.addTenantMode = false;
    this.showAddTenants = false;
    this.editTenantWithIndustry = null;
    this.editIndustryName = "";
    this.editTenantName = "";
  }

  findIconAltTitle = (item: any) => {
    let card = "imgSEO.cardicons.";
    if (item.id == 101) {
      card += "gross";
    } else if (item.id == 102) {
      card += "index";
    } else {
      card += "investorsurvey";
    }
    return this.siilaTranslationService.getTranslationFromJSONFiles(card);
  };

  handlePieClick(event, chartContext, config, industryPercentagesList) {
    const tenant =
      industryPercentagesList.industryPercentages[config.dataPointIndex];
    this.tenantAreas = industryPercentagesList.tenantAreas.filter(
      item => item.categoryName == tenant.name
    );
  }

  translateLabel(name) {
    return this.i18.getTranslation(name);
  }

  setQuarter(date) {
    let [year, month, day] = date.split("-").map(Number);
    let input = new Date(year, month - 1, day);
    let quarter = Math.floor((input.getMonth() + 3) / 3);
    return `${quarter} ${
      this.browserLang === "pt" || this.browserLang === "es" ? "T" : "Q"
    } ${this.datePipe.transform(input, "yyyy")}`;
  }

  async validateSesion() {
    if (localStorage.getItem("token")) {
      return this.setUser();
    } else {
      let isValidToken = this.deviceRegistration.validateCookie();

      if (isValidToken) {
        let account = await this.accountService.getAccount();
        localStorage.setItem("user", JSON.stringify(account));
        return this.setUser();
      }
    }

    return false;
  }

  setUser() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.user = user;
    this.isAdmin = user?.roles?.includes("ROLE_ADMIN");
    this.isResearcher = user?.roles?.includes("ROLE_RESEARCH");
    this.isResearchAdmin =
      user?.roles?.includes("ROLE_COUNTRY_MANAGER") ||
      user?.roles?.includes("ROLE_RESEARCH_LEAD");

    if (
      !user?.roles?.includes("ROLE_COUNTRY_MANAGER") &&
      !user?.roles?.includes("ROLE_RESEARCH_LEAD") &&
      !user?.roles?.includes("ROLE_RESEARCH")
    ) {
      this.snackBar.open("You don't have access to Research section", "Close", {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: "center",
        verticalPosition: "top"
      });
      this.router.navigateByUrl("/");
      return false;
    }
    return true;
  }

  edit() {
    window.open(
      `${environment.serverURL}/#!/building/update/${this.id}`,
      "blank"
    );
  }

  spot() {
    window.open(`${environment.spotURL}`, "blank");
  }

  transactionsPage(id = null) {
    window.open(
      `${environment.serverURL}/#!/building/${id ? id : this.id}/transactions`,
      "blank"
    );
  }

  splitOwners(owners) {
    this.owners = owners ? owners.split(",") : [];
  }

  splitDevelopers(developers) {
    this.developers = developers ? developers.split(",") : [];
  }

  openCompanySearch(item) {
    window.open(
      `${environment.serverURL}/#!/companySearch?companyName=${item}`,
      "blank"
    );
  }

  suggestEdit() {
    this.dialogRef = this.dialog.open(SendNotificationComponent, {
      data: this.property.id
    });
    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  slickNext(slick) {
    slick.slickNext();
  }

  slickPrev(slick) {
    slick.slickPrev();
  }

  headerImageNews(item: string): string {
    const url = item.replace("***", "640w");
    return `${environment.cloudfrontURL}${url}`;
  }

  openNews(id: number, title, homeNews = false) {
    if (homeNews) {
      this.router.navigateByUrl("resource");
    } else {
      this.newsNavigate.openNews(
        id,
        title.urlTitle ? title.urlTitle : title.headline,
        this.isMobile,
        "",
        false,
        this.browserLang,
        true,
        null
      );
    }
  }

  isMultifamily() {
    return this.property && this.property.buildingType.id == 9001;
  }

  openSpot() {
    window.open(environment.spotURL, "_blank");
  }

  sumBomaArea() {
    let sumBoma = 0;
    this.vacantAreaList.forEach(function(boma) {
      sumBoma += boma.bomaArea;
    });

    return sumBoma;
  }

  sumArea() {
    let sumArea = 0;
    this.vacantAreaList.forEach(function(area) {
      sumArea += area.area;
    });

    return sumArea;
  }

  abbreviatedText(text: string): string {
    const value = text ? text.split(" ") : [];
    if (value.length > 1) {
      const firstLetter = value[0].charAt(0);
      const lastLetter = value[value.length - 1].charAt(0);
      return `${firstLetter}${lastLetter}`;
    } else if (value.length === 1) {
      return value[0].charAt(0);
    } else {
      return text;
    }
  }

  validateTrnaslate(text) {
    return this.siilaTranslationService.getTranslation(text);
  }

  sanitizeDescription(): void {
    this.property.notes = this.validateTrnaslate(this.property.notes);
    if (this.property.notes && this.property.notes.length > 1) {
      this.property.notes = this.sanitizer.bypassSecurityTrustHtml(
        this.property.notes
      );
    }
  }

  navigateToFund(id) {
    window.open(`${environment.serverURL}/#!/realEstateFund/${id}`, "blank");
  }

  isMX() {
    return this.platformId && this.platformId == "mx";
  }

  createColumnVisibilityMap(unitsMix: UnitMix[]): Map<string, boolean> {
    const columnMap = new Map<string, boolean>();

    // Define columns to check
    const columnsToCheck = [
      "floorPlan",
      "numberOfUnits",
      "avgArea",
      "cqAvgMonthlyRent",
      "monthlyrentsm",
      "cqAvgMonthlyRentFurnished",
      "monthlyrentsmf",
      "detailsName"
    ];

    columnsToCheck.forEach(column => columnMap.set(column, false));

    for (const unit of unitsMix) {
      for (const column of columnsToCheck) {
        const value = unit[column as keyof UnitMix];
        if (
          !columnMap.get(column) &&
          value != null &&
          value !== "" &&
          value !== 0
        ) {
          columnMap.set(column, true);
        }
      }

      if (Array.from(columnMap.values()).every(Boolean)) {
        break;
      }
    }

    return columnMap;
  }

  generateAreasMeasurementsOpts(
    property: any,
    occupancies?: any
  ): PropertyOptions[] {
    let areasMeasurementsOpts: PropertyOptions[] = [];

    var buildingAreaOpt: PropertyOptions = {
      name: "buildingArea",
      value: this.commonService.formatNumberTo(property?.rentableArea, 2),
      displayName: this.i18.get("detail.buildingArea"),
      enabled: property?.rentableArea
    };
    this.addPropertyOptionTo(buildingAreaOpt, areasMeasurementsOpts);

    var landAreaOpt: PropertyOptions = {
      name: "landArea",
      value: this.commonService.formatNumberTo(property?.landArea, 2),
      displayName: this.i18.get("detail.landAread"),
      enabled: property?.landArea
    };
    this.addPropertyOptionTo(landAreaOpt, areasMeasurementsOpts);

    var numUnitsOpt: PropertyOptions = {
      name: "numUnits",
      value: this.commonService.formatNumberTo(property?.numUnits),
      displayName: this.i18.get("detail.numUnits"),
      enabled: property?.numUnits
    };
    this.addPropertyOptionTo(numUnitsOpt, areasMeasurementsOpts);

    if (occupancies && occupancies.length > 0) {
      var latestOccupancy = occupancies[occupancies.length - 1].occupancy;

      var latestOccupancyOpt: PropertyOptions = {
        name: "latestOccupancy",
        value: this.commonService.formatNumberTo(latestOccupancy) + "%",
        displayName: this.i18.get("detail.occupancy"),
        enabled: latestOccupancy
      };
      this.addPropertyOptionTo(latestOccupancyOpt, areasMeasurementsOpts);
    }

    var parkingSpacesOpt: PropertyOptions = {
      name: "parkingSpaces",
      value: this.commonService.formatNumberTo(property?.parkingSpaces),
      displayName: this.i18.get("detail.parkingSpaces"),
      enabled: property?.parkingSpaces
    };
    this.addPropertyOptionTo(parkingSpacesOpt, areasMeasurementsOpts);

    var parkingTypeOpt: PropertyOptions = {
      name: "parkingType",
      value: this.i18.getTranslation(property?.parkingType?.name),
      displayName: this.i18.get("detail.parkingType"),
      enabled: property?.parkingType?.name
    };
    this.addPropertyOptionTo(parkingTypeOpt, areasMeasurementsOpts);

    var ceilingHeightOpt: PropertyOptions = {
      name: "ceilingHeight",
      value: this.commonService.formatNumberTo(property?.ceilingHeight, 2),
      displayName: this.i18.get("detail.ceilingHeight"),
      enabled: property?.ceilingHeight
    };
    this.addPropertyOptionTo(ceilingHeightOpt, areasMeasurementsOpts);

    var totalStoriesOpt: PropertyOptions = {
      name: "totalStories",
      value: this.commonService.formatNumberTo(property?.numberStories),
      displayName: this.i18.get("detail.totalStories"),
      enabled: property?.numberStories
    };
    this.addPropertyOptionTo(totalStoriesOpt, areasMeasurementsOpts);

    var apartmentStoriesOpt: PropertyOptions = {
      name: "apartmentStories",
      value: this.commonService.formatNumberTo(property?.apartmentStories),
      displayName: this.i18.get("detail.apartmentStories"),
      enabled: property?.apartmentStories
    };
    this.addPropertyOptionTo(apartmentStoriesOpt, areasMeasurementsOpts);

    var retailStoriesOpt: PropertyOptions = {
      name: "retailStories",
      value: this.commonService.formatNumberTo(property?.retailStories),
      displayName: this.i18.get("detail.retailStories"),
      enabled: property?.retailStories
    };
    this.addPropertyOptionTo(retailStoriesOpt, areasMeasurementsOpts);

    var parkingStoriesOpt: PropertyOptions = {
      name: "parkingStories",
      value: this.commonService.formatNumberTo(property?.parkingStories),
      displayName: this.i18.get("detail.parkingStories"),
      enabled: property?.parkingStories
    };
    this.addPropertyOptionTo(parkingStoriesOpt, areasMeasurementsOpts);

    var numElevators = this.commonService.formatNumberTo(property?.elevators);
    var numElevatorsOpt: PropertyOptions = {
      name: "numElevators",
      value: numElevators ? numElevators : 0,
      displayName: this.i18.get("detail.numElevators"),
      enabled: property?.hasElevators && numElevators && numElevators !== "0"
    };
    this.addPropertyOptionTo(numElevatorsOpt, areasMeasurementsOpts);

    return areasMeasurementsOpts;
  }

  addPropertyOptionTo(
    propertyOption: PropertyOptions,
    optionCollection: PropertyOptions[]
  ) {
    if (propertyOption.enabled) {
      optionCollection.push(propertyOption);
    }
  }

  /* openChartPopup() {
    const chartModal = new Modal(this.chartModal.nativeElement, {
      backdrop: false, // or 'true'
      keyboard: false // Prevent closing with keyboard
    });
    chartModal.show();
    NgbModal
  } */

  openChartPopup(content: any, mode: string) {
    if (mode == "edit") {
      setTimeout(() => {
        this.selectedTenant.checkboxEdit = true;
      }, 10);
    }

    if (mode == "moveOut") {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveOut = true;
      }, 10);
    }

    if (mode == "moveIn") {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveIn = true;
      }, 10);
    }

    this.modalService.open(content, {
      centered: true,
      backdrop: false,
      keyboard: false,
      windowClass: "custom-modal-class-tenants",
      backdropClass: "custom-modal-class-tenants"
    });
  }

  isFutureDate(date) {
    if (date) {
      const currentDate = new Date();
      const parsedDate = new Date(date + "T00:00:00");
      if (parsedDate > currentDate) {
        this.countPlannedDate++;
      }
      return parsedDate > currentDate ? this.formatDate(date) : "";
    }
    return "";
  }

  isPastDate(date) {
    if (date) {
      const currentDate = new Date();
      const parsedDate = new Date(date + "T00:00:00");
      return parsedDate > currentDate ? "" : this.formatDate(date);
    } else {
      return "";
    }
  }

  formatDate(value) {
    if (value != "") {
      const date = new Date(value + "T00:00:00");
      const month = date.toLocaleString("en", { month: "short" });
      const year = date.getFullYear();

      return this.browserLang === "es"
        ? `${monthNamesMap[month]} ${year}`
        : `${month} ${year}`;
    }
    return value;
  }

  getHistoricOccupancyList() {
    // Define deliveredDate object from this.property.deliveredDate, also validate if it is not defined
    const deliveredDate = this.property.deliveredDate
      ? new Date(this.property.deliveredDate)
      : null;

    if (this.marketRentList && this.historicOccupancies) {
      this.historicOccupancies.forEach(historicOccupancy => {
        const matchingMarketRent = this.marketRentList.find(
          marketRent =>
            marketRent.dateSurveyed === historicOccupancy.dateSurveyed
        );

        if (matchingMarketRent) {
          historicOccupancy.marketRent = matchingMarketRent.marketRent;
        }
      });
      if (this.property.buildingType.id != 3001) {
        // Remove elements from this.historicOccupancies that have a dateSurveyed greater or equals 2021 and less than deliveredDate
        this.historicOccupancies = this.historicOccupancies.filter(
          item =>
            new Date(item.dateSurveyed).getFullYear() < 2021 &&
            (!deliveredDate || new Date(item.dateSurveyed) > deliveredDate)
        );
      }
    }
    this.historicOccupancies.forEach(date => {
      date.dateSurveyed = this.setQuarter(date.dateSurveyed);
    });
  }

  translateStatus(status: string): string {
    if (status === "Active") {
      return "Activo";
    } else if (status === "Inactive") {
      return "Inactivo";
    }
  }

  onInputChange(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.searchSubject.next(input);
  }

  onInputChangeMXN(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.mxnInputSubject.next(input);
  }

  onInputChangeUSD(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    this.usdInputSubject.next(input);
  }

  fetchAutocompleteResults(query: string): Observable<any[]> {
    const apiUrl = `https://your-api.com/search?query=${query}`;
    return this.http.get<any[]>(apiUrl);
  }

  onSelectOption(option: any): void {
    if (option.tenantName.includes("new tenant")) {
      this.addTenantMode = true;
      this.showAddTenants = false;
      this.autocompleteResults = [];
    } else {
      this.editTenantWithIndustry = option;
      this.editTenantName = option.tenantName;
      this.editIndustryName = option.industryName;
      this.autocompleteResults = []; // Clear the autocomplete dropdown
    }
  }

  resetModalValues() {
    this.editTenantWithIndustry = null;
    this.editIndustryName = "";
    this.editTenantName = "";

    this.editMoveOutDate = null;
    this.editAskingRentMXN = null;
    this.editAskingRentUSD = null;

    this.autocompleteResults = [];
    this.isLoading = false;
    this.newTenantArea = null;
    this.splitSuite = false;
    this.moveInSameQuarter = false;

    this.selectedMarketSegment = null;
    this.showAddTenants = false;
    this.addTenantMode = false;
  }

  discardChanges() {
    if (this.currentAction == "edit") {
      this.selectedTenant.checkboxEdit = false;
      this.selectedTenant.actionEdit = null;
      this.resetModalValues();
      this.modalService.dismissAll();
    }

    if (this.currentAction == "moveOut") {
      this.selectedTenant.checkboxMoveOut = false;
      this.selectedTenant.actionMoveOut = null;
      this.selectedTenant.checkboxMoveIn = false;
      this.selectedTenant.actionMoveIn = null;
      this.resetModalValues();
      this.modalService.dismissAll();
    }

    if (this.currentAction == "moveIn") {
      this.selectedTenant.checkboxMoveIn = false;
      this.selectedTenant.actionMoveIn = null;
      this.resetModalValues();
      this.modalService.dismissAll();
    }
  }

  /*   onCheckboxClick(event: Event, tenantRow: any, action): void {
    this.selectedTenant = tenantRow;
    this.currentAction = action;
    this.resetModalValues();
    if (action == "edit") {
      if (
        this.selectedTenant.action &&
        this.selectedTenant.action.actionType == 0
      ) {
        // Load this action
        this.editTenantName = this.selectedTenant.action.tenantNameEdit;
        if (this.selectedTenant.action.marketSegment) {
          this.editIndustryName = this.selectedTenant.action.marketSegment.name;
        }
      } else {
        //this.resetModalValues();
      }
    }

    if (action == "moveOut") {
      this.editTenantName = this.selectedTenant.tenantName;
      if (
        this.selectedTenant.action &&
        this.selectedTenant.action.actionType == 1
      ) {
        this.moveInSameQuarter = this.selectedTenant.action.moveInSameQuarter;
        this.editMoveOutDate = this.selectedTenant.action.dateAction;
        this.editAskingRentMXN = this.selectedTenant.action.askingRentPerAreaMXN;
        this.editAskingRentUSD = this.selectedTenant.action.askingRentPerAreaUSD;
      } else {
        //this.resetModalValues();
      }
    }

    if (action == "moveIn") {
      if (
        this.selectedTenant.action &&
        this.selectedTenant.action.actionType == 2
      ) {
        this.editTenantName = this.selectedTenant.action.tenantNameEdit;
        this.editMoveOutDate = this.selectedTenant.action.dateAction;
        if (this.selectedTenant.action.marketSegment) {
          this.editIndustryName = this.selectedTenant.action.marketSegment.name;
        }
        this.splitSuite = this.selectedTenant.action.splitSuite;
        this.newTenantArea = this.selectedTenant.action.newTenantArea
          ? this.selectedTenant.action.newTenantArea
          : 0;
      }
    }

    this.openChartPopup(this.chartModal, action);
  } */

  onCheckboxClick(event: Event, tenantRow: any, action): void {
    this.selectedTenant = tenantRow;
    this.currentAction = action;
    this.resetModalValues();
    if (action == "edit") {
      if (this.selectedTenant.actionEdit) {
        // Load this action
        this.editTenantName = this.selectedTenant.actionEdit.tenantNameEdit;
        if (this.selectedTenant.actionEdit.marketSegment) {
          this.editIndustryName = this.selectedTenant.actionEdit.marketSegment.name;
        }
      } else {
        //this.resetModalValues();
      }
    }

    if (action == "moveOut") {
      this.editTenantName = this.selectedTenant.tenantName;
      if (this.selectedTenant.actionMoveOut) {
        this.moveInSameQuarter = this.selectedTenant.actionMoveOut.moveInSameQuarter;
        this.editMoveOutDate = this.selectedTenant.actionMoveOut.dateAction;
        this.editAskingRentMXN = this.selectedTenant.actionMoveOut.askingRentPerAreaMXN;
        this.editAskingRentUSD = this.selectedTenant.actionMoveOut.askingRentPerAreaUSD;
      } else {
        //this.resetModalValues();
      }
    }

    if (action == "moveIn") {
      if (this.selectedTenant.actionMoveIn) {
        this.editTenantName = this.selectedTenant.actionMoveIn.tenantNameEdit;
        this.editMoveOutDate = this.selectedTenant.actionMoveIn.dateAction;
        if (this.selectedTenant.actionMoveIn.marketSegment) {
          this.editIndustryName = this.selectedTenant.actionMoveIn.marketSegment.name;
        }
        this.splitSuite = this.selectedTenant.actionMoveIn.splitSuite;
        this.newTenantArea = this.selectedTenant.actionMoveIn.newTenantArea
          ? this.selectedTenant.actionMoveIn.newTenantArea
          : 0;
      }
    }

    this.openChartPopup(this.chartModal, action);
  }

  saveChanges() {
    if (this.currentAction == "edit" || this.currentAction == "moveIn") {
      let actionObj =
        this.currentAction == "edit"
          ? this.selectedTenant.actionEdit
          : this.selectedTenant.actionMoveIn;
      if (
        !this.addTenantMode &&
        !this.editTenantWithIndustry &&
        actionObj &&
        actionObj.marketSegment
      ) {
        this.editTenantWithIndustry = {};
        this.editTenantWithIndustry.industryName = actionObj.marketSegment.name;
        this.editTenantWithIndustry.industryId = actionObj.marketSegment.id;
      } else {
        if (!this.editTenantWithIndustry && this.selectedMarketSegment) {
          this.editTenantWithIndustry = {};
          this.editTenantWithIndustry.industryName = this.selectedMarketSegment.name;
          this.editTenantWithIndustry.industryId = this.selectedMarketSegment.id;
        }
      }
    }

    if (this.currentAction == "edit") {
      this.selectedTenant.actionEdit = {
        actionType: 0,
        tenantNameEdit: this.editTenantName,
        marketSegment: {
          name: this.editTenantWithIndustry.industryName,
          id: this.editTenantWithIndustry.industryId
        },
        buildingTenant: {
          id: this.selectedTenant.id
        },
        dateAction: new Date().toISOString()
      };
    }

    if (this.currentAction == "moveOut") {
      this.selectedTenant.actionMoveOut = {
        actionType: 1,
        dateAction: this.editMoveOutDate,
        askingRentPerAreaMXN: this.editAskingRentMXN,
        askingRentPerAreaUSD: this.editAskingRentUSD,
        buildingTenant: {
          id: this.selectedTenant.id
        }
      };

      this.selectedTenant.actionMoveOut.moveInSameQuarter = this.moveInSameQuarter;
    }

    if (this.currentAction == "moveIn") {
      this.selectedTenant.actionMoveIn = {
        actionType: 2,
        tenantNameEdit: this.editTenantName,
        marketSegment: {
          name: this.editTenantWithIndustry.industryName,
          id: this.editTenantWithIndustry.industryId
        },
        dateAction: this.editMoveOutDate,
        buildingTenant: {
          id: this.selectedTenant.id
        }
      };
      if (this.splitSuite) {
        this.selectedTenant.actionMoveIn.newTenantArea = this.newTenantArea;
        this.selectedTenant.actionMoveIn.splitSuite = true;
      } else {
        this.selectedTenant.actionMoveIn.newTenantArea = null;
        this.selectedTenant.actionMoveIn.splitSuite = false;
      }
    }

    this.saveData();
    this.modalService.dismissAll();
  }

  updateCheckboxValues(tenantsLastQuarter) {
    /* tenantsLastQuarter.forEach(tenant => {
      if (tenant.action && tenant.action.actionType == 0) {
        tenant.checkboxEdit = true;
      }
      if (tenant.action && tenant.action.actionType == 1) {
        tenant.checkboxMoveOut = true;
      }
      if (tenant.action && tenant.action.actionType == 2) {
        tenant.checkboxMoveIn = true;
      }
    }); */

    tenantsLastQuarter.forEach(tenant => {
      if (tenant.actionEdit) {
        tenant.checkboxEdit = true;
      }
      if (tenant.actionMoveOut) {
        tenant.checkboxMoveOut = true;
      }
      if (tenant.actionMoveIn) {
        tenant.checkboxMoveIn = true;
      }
    });
  }

  closeModal() {
    if (!this.selectedTenant.actionEdit) {
      setTimeout(() => {
        this.selectedTenant.checkboxEdit = false;
      }, 10);
    }
    if (!this.selectedTenant.actionMoveOut) {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveOut = false;
      }, 10);
    }
    if (!this.selectedTenant.actionMoveIn) {
      setTimeout(() => {
        this.selectedTenant.checkboxMoveIn = false;
      }, 10);
    }
    this.modalService.dismissAll();
  }

  validateSaveButton() {
    if (this.currentAction == "edit") {
      let validation1 = this.editTenantName && this.editIndustryName;
      let validation2 =
        this.addTenantMode && this.selectedMarketSegment && this.editTenantName;
      return validation1 || validation2;
    }

    if (this.currentAction == "moveOut") {
      return this.editMoveOutDate;
    }

    if (this.currentAction == "moveIn") {
      let validation1 =
        this.editTenantName && this.editIndustryName && this.editMoveOutDate;
      let validation2 = true;
      if (
        this.splitSuite &&
        (this.newTenantArea > this.selectedTenant.area - 1 ||
          this.newTenantArea <= 0)
      ) {
        validation2 = false;
      }
      return validation1 && validation2;
    }
  }

  saveData() {
    // Get the list of actions from this.tenantsLastQuarter
    const actions = [];
    const actionsEdit = this.tenantsLastQuarter.map(
      tenant => tenant.actionEdit
    );
    const actionsMoveOut = this.tenantsLastQuarter.map(
      tenant => tenant.actionMoveOut
    );
    const actionsMoveIn = this.tenantsLastQuarter.map(
      tenant => tenant.actionMoveIn
    );
    const allActions = actions.concat(
      actionsEdit,
      actionsMoveOut,
      actionsMoveIn
    );
    if (allActions.length > 0) {
      this.saveTenantsData(allActions).then((resp: any) => {
        if (resp) {
          this.snackBar.open("Changes saved successfully!", "Close", {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top"
          });
          this.reloadTableData();
        }
      });
    }
  }

  discardAllChanges() {
    this.propertyService.restartTenantsConfig(this.config.id).then(resp => {
      this.snackBar.open(
        "All changes were removed. Status changed to INITIAL",
        "Close",
        {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: "center",
          verticalPosition: "top"
        }
      );
      this.reloadTableData();
    });
  }

  removeFromStats() {
    this.propertyService
      .markPropertyAsRemovedFromStats(this.config.id)
      .then(resp => {
        this.snackBar.open(
          "This property was marked as REMOVED FROM STATS. Still needs to be approved by LEAD",
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top"
          }
        );
        this.reloadTableData();
      });
  }

  saveTenantsData(actions) {
    return this.http
      .post(
        `${environment.apiURL}/building-tenant/tenant-actions`,
        this.tenantsLastQuarter
      )
      .toPromise();
  }

  getGLAFromTenants() {
    let gla = 0;
    this.tenantsLastQuarter.forEach(tenant => {
      if (tenant.area) {
        gla += tenant.area;
      }
    });

    return gla;
  }

  getConfigStatus() {
    if (this.config) {
      // return this.config.status but in upppercase
      return this.config.status.toUpperCase();
    }

    return "";
  }

  getSubmitButtonLabel() {
    switch (this.getConfigStatus()) {
      case "INITIAL":
        return "SUBMIT FOR LEAD APPROVAL";
      case "REJECTED":
        return "SUBMIT FOR LEAD APPROVAL";
      case "APPROVED":
        return "APPROVED BY LEAD";
      case "SUBMITTED":
        return "SUBMITTED";
    }

    return "";
  }

  getTooltipTextForApproveButton() {
    if (this.getConfigStatus() == "APPROVED") {
      return "These changes were already approved.";
    } else if (this.getConfigStatus() == "INITIAL") {
      return "Changes need to be submitted for approval first.";
    } else {
      return null;
    }
  }

  getApproveButtonLabel() {
    if (this.getConfigStatus() == "APPROVED") {
      return "APPROVED BY LEAD";
    }
    return "APPROVE";
  }

  getConfigStatusLabel() {
    if (this.config.removedFromStats) {
      return (
        this.getConfigStatus().toUpperCase() + " - MARK AS REMOVED FROM STATS"
      );
    }

    return this.getConfigStatus();
  }

  getYearFromFirstTenant() {
    if (this.tenantsLastQuarter.length > 0) {
      return new Date(this.tenantsLastQuarter[0].dateEntered).getFullYear();
    }
    return "";
  }

  getQuarterFromFirstTenant() {
    if (this.tenantsLastQuarter.length > 0) {
      let val = new Date(this.tenantsLastQuarter[0].dateEntered);
      let quarter = Math.floor((val.getMonth() + 3) / 3);
      return quarter;
    }
    return;
  }

  getModalTitle() {
    if (this.currentAction == "edit") {
      return "Edit Tenant";
    }
    if (this.currentAction == "moveOut") {
      return "Move Out Tenant";
    }
    if (this.currentAction == "moveIn") {
      return "Move In Tenant";
    }
  }

  reloadTableData() {
    this.propertyService
      .getLastQuarterTenantsInfo(this.id)
      .then((resp: any) => {
        this.tenantsLastQuarter = resp.buildingTenants;
        this.config = resp.config;
        this.comments = resp.comments;
        this.updateCheckboxValues(this.tenantsLastQuarter);
      });
  }

  openConfirmationDialog(mode: number): void {
    this.confirmationDialogMode = mode;
    const dialogRef = this.dialog.open(this.confirmationDialog);

    dialogRef.afterClosed().subscribe(result => {});
  }

  getConfirmationDialogMessage() {
    if (this.confirmationDialogMode == 1) {
      return "Are you sure you want to submit these changes for approval?";
    }
    if (this.confirmationDialogMode == 2) {
      return "Are you sure you want to approve these changes?";
    }
    if (this.confirmationDialogMode == 3) {
      return "Are you sure you want to mark this property as removed from stats?";
    }
  }

  openCommentsDialog(): void {
    this.rejectionMode = false;
    const dialogRef = this.dialog.open(this.commentsDialog);

    dialogRef.afterClosed().subscribe(result => {});
  }

  openRejectionDialog(): void {
    this.rejectionMode = true;
    const dialogRef = this.dialog.open(this.commentsDialog);

    dialogRef.afterClosed().subscribe(result => {});
  }

  submitOrApprove(): void {
    switch (this.confirmationDialogMode) {
      case 1:
        this.submitChanges();
        break;
      case 2:
        this.approveChanges();
        break;
      case 3:
        this.removeFromStats();
        break;
    }
    this.dialog.closeAll();
  }

  submitChanges(): void {
    this.propertyService
      .submitTenantChangesForApproval(this.config.id)
      .then(resp => {
        this.snackBar.open("Changes submitted for approval!", "Close", {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: "center",
          verticalPosition: "top"
        });
        this.reloadTableData();
      });
    this.dialog.closeAll(); // Close the dialog
  }

  approveChanges(): void {
    this.propertyService.approveTenantChanges(this.config.id).then(resp => {
      this.snackBar.open("Changes are now approved!", "Close", {
        duration: 3000, // Duration in milliseconds
        horizontalPosition: "center",
        verticalPosition: "top"
      });
      this.reloadTableData();
    });
    this.dialog.closeAll(); // Close the dialog
  }

  cancelSubmission(): void {
    this.dialog.closeAll(); // Close the dialog
  }

  formatComment(comment: any): string {
    const date = this.formatDates(comment.createdDate);
    return `${date} - ${comment.comment} - by ${comment.user.firstName} ${comment.user.lastName} `;
  }

  addNewComment() {
    this.showNewCommentBox = false;
    this.comments.push({
      configId: this.config.id,
      comment: this.newComment,
      createdDate: new Date(),
      user: {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName
      }
    });
    this.newComment = "";
  }

  showNewComment() {
    this.showNewCommentBox = true;
    this.newComment = "";
  }

  rejectAndSaveComments() {
    if (this.newComment && this.newComment.length > 3) {
      this.addNewComment();
    }

    this.propertyService
      .rejectAndAddComments(this.config.id, this.comments)
      .then(resp => {
        this.snackBar.open(
          "Status changed to INITIAL. Comments saved successfully!",
          "Close",
          {
            duration: 3000, // Duration in milliseconds
            horizontalPosition: "center",
            verticalPosition: "top"
          }
        );

        this.reloadTableData();
      });
    this.dialog.closeAll(); // Close the dialog
  }

  saveComments() {
    if (this.newComment && this.newComment.length > 3) {
      this.addNewComment();
    }
    this.propertyService
      .saveComments(this.config.id, this.comments)
      .then(resp => {
        this.snackBar.open("Comments saved successfully!", "Close", {
          duration: 3000, // Duration in milliseconds
          horizontalPosition: "center",
          verticalPosition: "top"
        });

        this.reloadTableData();
      });
    this.dialog.closeAll(); // Close the dialog
  }
}
