<div class="nav-container">
  <div class="col-md-12 color-span nav-detail">
    <div class="col-md-12 title-row f-left mt-1">
      <span class="title">
        Tenants information for {{ property?.title | langSelector }}
      </span>
    </div>
  </div>
</div>
<div class="content">
  <app-loading-spinner *ngIf="!showDetail"></app-loading-spinner>
  <div *ngIf="showDetail" class="content-detail">
    <div class="col-md-12 mt-4 ">
      <div class=" key-columns">
        <div class="col-md-6" [ngClass]="{ 'p-0': isMobile }">
          <div class="col-md-12 key-facts mt-2">
            Last Period Data
          </div>
          <div class="d-flex key-facts-columns border-table">
            <div class="column label-color">
              Year
            </div>
            <div class="column label-color2">
              {{ getYearFromFirstTenant() }}
            </div>
            <div class="column label-color">
              Period
            </div>
            <div class="column label-color2">
              {{ getQuarterFromFirstTenant() }}
            </div>
            <div class="column label-color">
              Property
            </div>
            <div class="column label-color2">
              {{ property?.title | langSelector }}
            </div>

            <div class="column label-color">
              GLA
            </div>
            <div class="column label-color2">
              {{ getGLAFromTenants() | localNumber: "1.2-2" }}
            </div>
            <div class="column label-color">
              Status
            </div>
            <div class="column label-color2">
              {{ getConfigStatusLabel() }}
            </div>
          </div>
        </div>
        <div class="col-md-6" [ngClass]="{ 'p-0': isMobile }">
          <div class="col-md-12 key-facts mt-2">
            Actions
          </div>
          <div
            class="key-facts-columns border-table actios-card"
            *ngIf="isResearcher"
          >
            <div
              class="column"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              *ngIf="getConfigStatus() == 'INITIAL'"
            >
              <button
                class="btn btn-primary"
                (click)="discardAllChanges()"
                style="width: 260px; font-weight: 600;"
                [disabled]="getConfigStatus() != 'INITIAL'"
              >
                RELOAD PRIOR QUARTER DATA
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                getConfigStatus() != 'INITIAL'
                  ? 'These changes were already submitted.'
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary submit-approval"
                (click)="openConfirmationDialog(1)"
                [disabled]="getConfigStatus() != 'INITIAL'"
              >
                {{ getSubmitButtonLabel() }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                getConfigStatus() != 'INITIAL'
                  ? 'You cannot add changes, already submitted.'
                  : null
              "
              matTooltipPosition="below"
              *ngIf="!config.removedFromStats && getConfigStatus() == 'INITIAL'"
            >
              <button
                class="btn btn-primary reject-button"
                (click)="openConfirmationDialog(3)"
                style="width: 260px; font-weight: 600;"
                [disabled]="getConfigStatus() != 'INITIAL'"
              >
                REMOVE FROM STATS
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !comments || comments.length == 0
                  ? 'No comments available.'
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="openCommentsDialog()"
                [disabled]="!comments || comments.length == 0"
              >
                VIEW COMMENTS
              </button>
            </div>
          </div>

          <div
            class="key-facts-columns border-table"
            style="height: 200px; display: flex;
          align-content: center;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;"
            *ngIf="isResearchAdmin"
          >
            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
            >
              <button
                class="btn btn-primary reject-button"
                (click)="openRejectionDialog()"
                [disabled]="getConfigStatus() == 'INITIAL'"
              >
                REJECT
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="getTooltipTextForApproveButton()"
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary approve-button"
                (click)="openConfirmationDialog(2)"
                [disabled]="getConfigStatus() != 'SUBMITTED'"
              >
                {{ getApproveButtonLabel() }}
              </button>
            </div>

            <div
              class="column label-color"
              style="text-align: center; display: flex;
            align-items: center;
            justify-content: center;"
              [matTooltip]="
                !comments || comments.length == 0
                  ? 'No comments available.'
                  : null
              "
              matTooltipPosition="below"
            >
              <button
                class="btn btn-primary view-comments-button"
                (click)="openCommentsDialog()"
                [disabled]="!comments || comments.length == 0"
              >
                VIEW COMMENTS
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 tenantheader">
        <div class="col-md-12 key-facts">
          <span>Tenants info</span>
        </div>
        <div class="table-unitmix">
          <table class="table" *ngIf="!config.removedFromStats">
            <thead class="head-vacant title-row unitmixheaders">
              <tr>
                <th>Move-In</th>
                <th>
                  Floor/Module
                </th>
                <th>
                  Suite/Warehouse
                </th>
                <th>
                  Area (m²)
                </th>
                <th>
                  Tenant
                </th>
                <th>
                  Edit
                </th>
                <th>
                  Move-Out
                </th>
              </tr>
            </thead>
            <tbody class="border-table title-row unitmixtablerows">
              <tr *ngFor="let tenantRow of tenantsLastQuarter; let i = index">
                <td>
                  <input
                    type="checkbox"
                    [checked]="tenantRow.checkboxMoveIn"
                    [(ngModel)]="tenantRow.checkboxMoveIn"
                    [disabled]="
                      (tenantRow.tenantName ||
                        (tenantRow.tenantNameOption &&
                          tenantRow.tenantNameOption.id == 2)) &&
                      (!tenantRow.actionMoveOut ||
                        !tenantRow.actionMoveOut.moveInSameQuarter)
                    "
                    (click)="onCheckboxClick($event, tenantRow, 'moveIn')"
                  />
                </td>
                <td>
                  {{ tenantRow.floorModule }}
                </td>
                <td>
                  {{ tenantRow.suiteWarehouse }}
                </td>
                <td>
                  {{ tenantRow.area | localNumber: "1.2-2" }}
                </td>
                <td style="text-align: left;">
                  <span *ngIf="tenantRow.tenantName">{{
                    tenantRow.tenantName
                  }}</span>
                  <span *ngIf="!tenantRow.tenantName">{{
                    tenantRow.tenantNameOption.name | langSelector
                  }}</span>
                  <span *ngIf="tenantRow.marketSegment">
                    ({{ tenantRow.marketSegment.name | langSelector }})</span
                  >
                </td>
                <td>
                  <input
                    type="checkbox"
                    [disabled]="
                      tenantRow.tenantName ||
                      (tenantRow.tenantNameOption &&
                        tenantRow.tenantNameOption.id != 2)
                    "
                    [(ngModel)]="tenantRow.checkboxEdit"
                    [checked]="tenantRow.checkboxEdit"
                    (click)="onCheckboxClick($event, tenantRow, 'edit')"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    [disabled]="
                      tenantRow.tenantNameOption &&
                      tenantRow.tenantNameOption.id == 1
                    "
                    [checked]="tenantRow.checkboxMoveOut"
                    [(ngModel)]="tenantRow.checkboxMoveOut"
                    (click)="onCheckboxClick($event, tenantRow, 'moveOut')"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="removed-from-stats"
            *ngIf="config.removedFromStats"
            style="text-align: center;"
          >
            This property has been marked as removed from stats
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- NgbModal Template -->
  <ng-template #chartModal let-modal>
    <div class="custom-modal custom-modal-centered modal-80vh">
      <div class="modal-header">
        <h5 class="modal-title" style="font-weight: 600;">
          {{ getModalTitle() }}
        </h5>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="closeModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="table-tenant-popup">
          <div class="d-flex" *ngIf="currentAction == 'moveIn'">
            <div class="form-check form-switch" style="padding-left: 2.5em;">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                [(ngModel)]="splitSuite"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Split Suite</label
              >
            </div>
          </div>
          <table class="table" style="height: 400px;">
            <thead
              class="head-vacant"
              style="text-align: center; background-color: #5a86a3;"
            >
              <tr>
                <th style="width: 80px;">
                  Floor / Module
                </th>
                <th style="max-width: 100px;">
                  Suite / Warehouse
                </th>
                <th style="max-width: 100px;">
                  Area (m²)
                </th>
                <th style="min-width: 200px;">
                  Tenant
                </th>
                <th
                  *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                  style="max-width: 120px;"
                >
                  Industry
                </th>
                <th
                  *ngIf="
                    currentAction == 'moveOut' || currentAction == 'moveIn'
                  "
                  style="max-width: 120px;"
                >
                  Date
                </th>
                <th
                  *ngIf="currentAction == 'moveOut'"
                  style="max-width: 120px;"
                >
                  Asking Rent MXN
                </th>
                <th
                  *ngIf="currentAction == 'moveOut'"
                  style="max-width: 120px;"
                >
                  Asking Rent USD
                </th>

                <th
                  *ngIf="currentAction == 'moveOut'"
                  style="max-width: 120px;"
                >
                  Move In same Quarter
                </th>
              </tr>
            </thead>
            <tbody style="text-align: center;">
              <tr style="height: 50px;">
                <td>
                  {{ selectedTenant.floorModule }}
                </td>
                <td>
                  {{ selectedTenant.suiteWarehouse }}
                </td>
                <td *ngIf="!splitSuite">
                  {{ selectedTenant.area | localNumber: "1.2-2" }}
                </td>
                <td *ngIf="splitSuite">
                  <input
                    type="number"
                    [(ngModel)]="newTenantArea"
                    style="width: 120px;"
                    min="0"
                    max="{{ selectedTenant.area }}"
                  />
                </td>
                <td>
                  <div>
                    <div
                      style="position: relative; text-align: start;"
                      *ngIf="
                        currentAction != 'edit' && currentAction != 'moveIn'
                      "
                    >
                      <span> {{ editTenantName }} </span>
                    </div>
                    <div
                      style="position: relative; text-align: start; width: 100%;"
                      *ngIf="
                        currentAction == 'edit' || currentAction == 'moveIn'
                      "
                    >
                      <span *ngIf="addTenantMode"> {{ editTenantName }} </span>
                      <input
                        *ngIf="!addTenantMode"
                        type="text"
                        [(ngModel)]="editTenantName"
                        (input)="onInputChange($event)"
                        placeholder="Type to search..."
                        [ngClass]="{ loading: isLoading }"
                        style="width: 100%;"
                      />
                      <!-- Loading icon, only shown when isLoading is true -->
                      <span *ngIf="isLoading" class="loading-icon">🔄</span>

                      <!-- Autocomplete dropdown -->
                      <ul
                        *ngIf="autocompleteResults.length && !isLoading"
                        class="autocomplete-dropdown"
                        style="z-index: 1;"
                      >
                        <li
                          *ngFor="let option of autocompleteResults"
                          (click)="onSelectOption(option)"
                          [ngClass]="{
                            'blue-text': option.tenantName.includes(
                              'new tenant'
                            )
                          }"
                        >
                          {{ option.tenantName }}
                        </li>
                      </ul>
                    </div>
                    <div
                      style="text-align: left; font-size: smaller;"
                      *ngIf="showAddTenants && !addTenantMode"
                    >
                      <label
                        (click)="onAddTenant()"
                        style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                      >
                        Add as new tenant
                      </label>
                    </div>
                    <div
                      style="text-align: left; font-size: smaller;"
                      *ngIf="addTenantMode"
                    >
                      <label
                        (click)="onBackToSearchTenant()"
                        style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                      >
                        Back to search
                      </label>
                    </div>
                  </div>
                </td>
                <td
                  *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                >
                  <span *ngIf="editIndustryName && !addTenantMode">
                    {{ editIndustryName | langSelector }}</span
                  >
                  <select
                    [(ngModel)]="selectedMarketSegment"
                    style="width: 100%;"
                    *ngIf="addTenantMode"
                  >
                    <option
                      *ngFor="let segment of marketSegments"
                      [ngValue]="segment"
                    >
                      {{ segment.name | langSelector }}
                    </option>
                  </select>
                </td>
                <td
                  *ngIf="
                    currentAction == 'moveOut' || currentAction == 'moveIn'
                  "
                >
                  <input
                    type="date"
                    [(ngModel)]="editMoveOutDate"
                    (ngModelChange)="onDateChange($event)"
                    (keydown)="disableManualEntry($event)"
                    style="width: 120px;"
                  />
                </td>
                <td *ngIf="currentAction == 'moveOut'">
                  <input
                    type="number"
                    [(ngModel)]="editAskingRentMXN"
                    (input)="onInputChangeMXN($event)"
                    style="width: 120px;"
                    [matTooltip]="
                      !editMoveOutDate
                        ? 'Please enter a date for market rent currency exchange to process'
                        : null
                    "
                    matTooltipPosition="below"
                  />
                </td>
                <td *ngIf="currentAction == 'moveOut'">
                  <input
                    type="number"
                    [(ngModel)]="editAskingRentUSD"
                    (input)="onInputChangeUSD($event)"
                    style="width: 120px;"
                    [matTooltip]="
                      !editMoveOutDate
                        ? 'Please enter a date for market rent currency exchange to process'
                        : null
                    "
                    matTooltipPosition="below"
                  />
                </td>
                <td *ngIf="currentAction == 'moveOut'">
                  <input
                    type="checkbox"
                    [checked]="moveInSameQuarter"
                    [(ngModel)]="moveInSameQuarter"
                  />
                </td>
              </tr>
              <tr *ngIf="splitSuite" style="height: 50px;">
                <td>
                  {{ selectedTenant.floorModule }}
                </td>
                <td>{{ selectedTenant.suiteWarehouse }}-A</td>
                <td>
                  {{
                    selectedTenant.area - newTenantArea | localNumber: "1.2-2"
                  }}
                </td>
                <td>
                  <div style="position: relative; text-align: start;">
                    <span> Vacant </span>
                  </div>
                </td>
                <td>
                  <span></span>
                </td>
                <td>
                  <span></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="discardChanges()"
          *ngIf="getConfigStatus() == 'INITIAL' && isResearcher"
        >
          Discard changes
        </button>
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="getConfigStatus() != 'INITIAL' || !validateSaveButton()"
          *ngIf="getConfigStatus() == 'INITIAL' && isResearcher"
          (click)="saveChanges()"
        >
          Save
        </button>
        <button type="button" class="btn btn-secondary" (click)="closeModal()">
          Close
        </button>
      </div>
    </div>
  </ng-template>
  <ng-template #confirmationDialog>
    <div
      class="modal-header"
      style="padding: 0; border-bottom: 0; padding-bottom: 30px;"
    >
      <h4 class="modal-title" style="font-weight: 600;">
        Confirmation
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="cancelSubmission()"
      ></button>
    </div>
    <div mat-dialog-content>
      <p>
        {{ getConfirmationDialogMessage() }}
      </p>
    </div>
    <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="submitOrApprove()"
        [disabled]="getConfigStatus() == 'APPROVED'"
      >
        Confirm
      </button>
    </div>
  </ng-template>
  <ng-template #commentsDialog>
    <div
      class="modal-header"
      style="padding: 0; border-bottom: 0; padding-bottom: 30px;"
    >
      <h4 class="modal-title" style="font-weight: 600;">
        Rejection Comments
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="cancelSubmission()"
      ></button>
    </div>
    <div mat-dialog-content style="width: 550px;">
      <div style="max-height: 300px;overflow: auto;">
        <div *ngFor="let comment of comments; let i = index">
          <div class="comment-row">
            {{ formatComment(comment) }}
          </div>
          <br />
        </div>
        <div
          *ngIf="!comments || comments.length == 0"
          style="font-style: italic;
    color: grey;"
        >
          <div class="comment-row">
            No comments yet
          </div>
          <br />
        </div>
      </div>

      <div
        style="text-align: left; font-size: smaller;"
        *ngIf="!showNewCommentBox && isResearchAdmin"
        (click)="showNewComment()"
      >
        <label
          style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
        >
          Add new comment
        </label>
      </div>
      <div *ngIf="showNewCommentBox">
        <div style="padding-bottom: 10px;">
          <textarea
            [(ngModel)]="newComment"
            style="width: 100%; height: 100px;"
          ></textarea>
        </div>
        <div>
          <button
            class="btn btn-primary"
            (click)="addNewComment()"
            [disabled]="!newComment || newComment.length < 10"
          >
            Add
          </button>
        </div>
      </div>
    </div>
    <div class="modal-footer" style="padding: 0; padding-top: 10px; border: 0;">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
        *ngIf="isResearcher"
      >
        Close
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="cancelSubmission()"
        *ngIf="isResearchAdmin"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="saveComments()"
        *ngIf="isResearchAdmin"
      >
        Save Comments
      </button>
      <button
        type="button"
        class="btn btn-primary reject-button2"
        (click)="rejectAndSaveComments()"
        *ngIf="isResearchAdmin && rejectionMode"
        [disabled]="!comments || comments.length == 0"
      >
        Confirm Rejection
      </button>
    </div>
  </ng-template>
</div>
