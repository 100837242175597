import { Component, Input, OnInit } from "@angular/core";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-areas-measurements-br",
  templateUrl: "./areas-measurements-br.component.html",
  styleUrls: ["./areas-measurements-br.component.scss"]
})
export class AreasMeasurementsBrComponent implements OnInit {
  @Input() property: any;
  @Input() isMultifamily;
  @Input() areasMeasurementsOpts = [];
  @Input() hiddeMoreFeatures;
  typeOpt = 1;
  validateBomaList;
  constructor() {}

  ngOnInit(): void {
    this.validateBomaList = this.validateBomaData();
  }

  validateBomaData() {
    if (
      this.property?.bomaTotalArea ||
      this.property?.averageFloorBomaArea ||
      this.property?.bomaAvailableArea
    ) {
      return true;
    } else return false;
  }
}
