/* export class ContentType {
  contentCategoryId: number;
  id: number;
  name: string;
}

export class Content {
  contentType: ContentType;
  id: number;
}

export class HeaderImage {
  fullPath: string;
}

export class NewsSection {
  caption: string;
  createdByUserId?: any;
  createdDate?: any;
  description: string;
  id: number;
  imageId: number;
  modifiedByUserId?: any;
  modifiedDate?: any;
  newsId: number;
  norder: number;
}

export class NewsDetail {
  content: Content;
  createdByUserId?: any;
  createdDate: Date;
  headerImage: HeaderImage;
  headerImageCaption: string;
  headerImgId: number;
  headline: string;
  id: number;
  modifiedByUserId: number;
  modifiedDate: Date;
  newsSections: NewsSection[];
  primaryContent: string;
  sponsored: boolean;
  status?: any;
  step?: any;
  summary: string;
} */

import { SafeResourceUrl } from "@angular/platform-browser";

export interface Tagtype {
  id: number;
  name: string;
}

export interface ProductTag {
  customOrder: number;
  id: number;
  name: string;
  regionId: number;
  tagtype: Tagtype;
}

export interface PropertyTypeTag {
  customOrder: number;
  id: number;
  name: string;
  regionId: number;
  tagtype: Tagtype;
}

export interface StateTag {
  customOrder: number;
  id: number;
  name: string;
  regionId: number;
  tagtype: Tagtype;
}

export interface ContentTags {
  productTags: ProductTag[];
  propertyTypeTags: PropertyTypeTag[];
  stateTags: StateTag[];
}

export interface HeaderImage {
  fullPath: string;
}

export interface MainImg {
  fullPath: string;
}

export interface NewsSection {
  caption: string;
  createdByUserId: number;
  createdDate: Date;
  description: string;
  id: number;
  imageId: number;
  mainImg: MainImg;
  sectionImgsRES: ImageDTO[];
  modifiedByUserId?: number;
  modifiedDate?: Date;
  norder: number;
  videoURL: SafeResourceUrl;
}

export interface ImageDTO {
  fullPath: string;
}

export interface ExternalAuthor {
  companyName: string;
  createdByUserId: number;
  createdDate: Date;
  id: number;
  imageDTO: ImageDTO;
  imageId: number;
  modifiedByUserId?: number;
  modifiedDate?: Date;
  name: string;
  newsId: number;
  title: string;
}

export class NewsDetail {
  contentTags: ContentTags;
  createdByUserId: number;
  createdDate: Date;
  headerImage: HeaderImage;
  headerImageCaption: string;
  headerImgId: number;
  headline: string;
  id: number;
  modifiedByUserId: number;
  modifiedDate: Date;
  sectionsInfo: NewsSection[];
  primaryContent: string;
  sponsored: boolean;
  status: string;
  step: string;
  summary: string;
  topic?: any;
  internalAuthors: string[];
  externalAuthors: ExternalAuthor[];
  primaryContentPlainText: string;
  urlTitle: string;
  subscriberOnly: boolean;
}
