import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncateHtml"
})
export class TruncateHtmlPipe implements PipeTransform {
  transform(html: string, wordLimit: number = 150): string {
    const div = document.createElement("div");
    div.innerHTML = html;

    const wordCount = { count: 0 }; // Contador de palabras
    this.truncateNode(div, wordLimit, wordCount);

    return div.innerHTML;
  }

  private truncateNode(
    node: Node,
    wordLimit: number,
    wordCount: { count: number }
  ): boolean {
    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent || "";
      const words = text.split(/\s+/);

      if (wordCount.count + words.length > wordLimit) {
        const allowedWords = words.slice(0, wordLimit - wordCount.count);
        node.textContent = allowedWords.join(" ") + "..."; // Truncar y añadir puntos suspensivos
        wordCount.count = wordLimit; // Asegurar límite alcanzado
        return true; // Detener recorrido
      } else {
        wordCount.count += words.length; // Incrementar contador
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      for (let i = 0; i < node.childNodes.length; i++) {
        if (this.truncateNode(node.childNodes[i], wordLimit, wordCount)) {
          // Eliminar nodos sobrantes después del truncamiento
          while (node.childNodes.length > i + 1) {
            node.removeChild(node.childNodes[i + 1]);
          }
          return true;
        }
      }
    }
    return false;
  }
}
