<div class="nav-container">
  <div class="col-md-12 color-span nav-detail">
    <div class="col-md-12 title-row f-left mt-1">
      <span class="title">
        Stats Booking Dashboard
      </span>
    </div>
  </div>
</div>
<div class="content">
  <app-loading-spinner *ngIf="!showDetail"></app-loading-spinner>
  <div *ngIf="showDetail" class="content-detail">
    <div class="col-md-12 mt-4 ">
      <div class=" key-columns">
        <div class="col-md-6" [ngClass]="{ 'p-0': isMobile }">
          <div class="col-md-12 key-facts mt-2">
            Market Selection
          </div>
          <div class="d-flex key-facts-columns border-table">
            <div class="column label-color">
              Year
            </div>
            <div class="column label-color2">
              2024
            </div>
            <div class="column label-color">
              Period
            </div>
            <div class="column label-color2">
              Q4
            </div>
            <div class="column label-color">
              Market
            </div>
            <div class="column label-color2">
              <select
                [(ngModel)]="selectedMarketConfig"
                style="width: 100%;"
                (ngModelChange)="onMarketChange($event)"
              >
                <option *ngFor="let market of marketConfigs" [ngValue]="market">
                  {{ market.marketName | langSelector }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6" [ngClass]="{ 'p-0': isMobile }">
          <div class="col-md-12 key-facts mt-2">
            People
          </div>
          <div class="d-flex key-facts-columns border-table">
            <div class="column label-color">
              Research
            </div>
            <div class="column label-color2">
              {{ selectedMarketConfig.researcherName }}
            </div>
            <div class="column label-color">
              Research Lead
            </div>
            <div class="column label-color2">
              {{ selectedMarketConfig.leadResearcherName }}
            </div>
            <div class="column label-color">
              Country Manager
            </div>
            <div class="column label-color2">
              {{ selectedMarketConfig.countryManagerName }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 tenantheader">
        <div class="col-md-12 key-facts">
          <span>Research Configuration</span>
        </div>
        <div class="table-unitmix">
          <table class="table">
            <thead class="head-vacant title-row unitmixheaders">
              <tr>
                <th style="text-align: left;">Property Name</th>
                <th style="text-align: left;">
                  Tenant Stats Link
                </th>
                <th style="text-align: left;">
                  Changes
                </th>
                <th style="text-align: left; width: 180px;">
                  Research
                </th>
                <th style="text-align: left; width: 180px;">
                  Lead Approval
                </th>
                <th style="text-align: left; width: 180px;">
                  Exec Approval
                </th>
              </tr>
            </thead>
            <tbody
              class="border-table title-row unitmixtablerows"
              style="text-align: left; font-size: small;"
            >
              <tr *ngFor="let data of researchData; let i = index">
                <td>
                  <a
                    href="{{ getBuildingDetailURL(data.buildingId) }}"
                    target="_blank"
                    >{{ data.buildingName }}</a
                  >
                </td>
                <td>
                  <a
                    href="{{ getBuildingTenantChangesURL(data.buildingId) }}"
                    target="_blank"
                    >{{ getBuildingTenantChangesURL(data.buildingId) }}</a
                  >
                </td>
                <td style="text-align: center;">
                  {{ data.hasChanges ? "YES" : "NO" }}
                </td>
                <td>
                  <select
                    [(ngModel)]="data.researcherName"
                    (ngModelChange)="
                      onResearcherSelected($event, data.buildingId)
                    "
                    *ngIf="isResearchAdmin"
                    style="width: 100%;"
                  >
                    <option
                      *ngFor="let user of researchUsers"
                      [ngValue]="user.name"
                    >
                      {{ user.name }}
                    </option>
                  </select>
                  <span *ngIf="!isResearchAdmin">{{
                    data.researcherName
                  }}</span>
                </td>
                <td>
                  {{ data.leadResearcherName }}
                </td>
                <td>
                  {{ data.countryManagerName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- NgbModal Template -->
<ng-template #chartModal let-modal>
  <div class="custom-modal custom-modal-centered modal-80vh">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ getModalTitle() }}
      </h5>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeModal()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="table-tenant-popup">
        <div class="d-flex" *ngIf="currentAction == 'moveIn'">
          <div class="form-check form-switch" style="padding-left: 2.5em;">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckDefault"
              [(ngModel)]="splitSuite"
            />
            <label class="form-check-label" for="flexSwitchCheckDefault"
              >Split Suite</label
            >
          </div>
        </div>
        <table class="table" style="height: 400px;">
          <thead
            class="head-vacant"
            style="text-align: center; background-color: #5a86a3;"
          >
            <tr>
              <th style="width: 80px;">
                Floor / Module
              </th>
              <th style="max-width: 100px;">
                Suite / Warehouse
              </th>
              <th style="max-width: 100px;">
                Area (m²)
              </th>
              <th style="min-width: 200px;">
                Tenant
              </th>
              <th
                *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                style="max-width: 120px;"
              >
                Industry
              </th>
              <th
                *ngIf="currentAction == 'moveOut' || currentAction == 'moveIn'"
                style="max-width: 120px;"
              >
                Date
              </th>
              <th *ngIf="currentAction == 'moveOut'" style="max-width: 120px;">
                Asking Rent MXN
              </th>
              <th *ngIf="currentAction == 'moveOut'" style="max-width: 120px;">
                Asking Rent USD
              </th>
            </tr>
          </thead>
          <tbody style="text-align: center;">
            <tr style="height: 50px;">
              <td>
                {{ selectedTenant.floorModule }}
              </td>
              <td>
                {{ selectedTenant.suiteWarehouse }}
              </td>
              <td *ngIf="!splitSuite">
                {{ selectedTenant.area | localNumber: "1.2-2" }}
              </td>
              <td *ngIf="splitSuite">
                <input
                  type="number"
                  [(ngModel)]="newTenantArea"
                  style="width: 120px;"
                  min="0"
                  max="{{ selectedTenant.area }}"
                />
              </td>
              <td>
                <div
                  style="position: relative; text-align: start;"
                  *ngIf="currentAction != 'edit' && currentAction != 'moveIn'"
                >
                  <span> {{ editTenantName }} </span>
                </div>
                <div
                  style="position: relative; text-align: start; width: 100%;"
                  *ngIf="currentAction == 'edit' || currentAction == 'moveIn'"
                >
                  <span *ngIf="addTenantMode"> {{ editTenantName }} </span>
                  <input
                    *ngIf="!addTenantMode"
                    type="text"
                    [(ngModel)]="editTenantName"
                    (input)="onInputChange($event)"
                    placeholder="Type to search..."
                    [ngClass]="{ loading: isLoading }"
                    style="width: 100%;"
                  />
                  <!-- Loading icon, only shown when isLoading is true -->
                  <span *ngIf="isLoading" class="loading-icon">🔄</span>

                  <!-- Autocomplete dropdown -->
                  <ul
                    *ngIf="autocompleteResults.length && !isLoading"
                    class="autocomplete-dropdown"
                    style="z-index: 1;"
                  >
                    <li
                      *ngFor="let option of autocompleteResults"
                      (click)="onSelectOption(option)"
                    >
                      {{ option.tenantName }}
                    </li>
                  </ul>
                </div>
                <div
                  style="text-align: left; font-size: smaller;"
                  *ngIf="showAddTenants && !addTenantMode"
                >
                  <label
                    (click)="onAddTenant()"
                    style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                  >
                    Add as new tenant
                  </label>
                </div>
                <div
                  style="text-align: left; font-size: smaller;"
                  *ngIf="addTenantMode"
                >
                  <label
                    (click)="onBackToSearchTenant()"
                    style="cursor: pointer; color: #007bff; text-decoration: underline; margin: 0; padding: 0;"
                  >
                    Back to search
                  </label>
                </div>
              </td>
              <td *ngIf="currentAction == 'edit' || currentAction == 'moveIn'">
                <span *ngIf="editIndustryName && !addTenantMode">
                  {{ editIndustryName | langSelector }}</span
                >
                <select
                  [(ngModel)]="selectedMarketSegment"
                  style="width: 100%;"
                  *ngIf="addTenantMode"
                >
                  <option
                    *ngFor="let segment of marketSegments"
                    [ngValue]="segment"
                  >
                    {{ segment.name | langSelector }}
                  </option>
                </select>
              </td>
              <td
                *ngIf="currentAction == 'moveOut' || currentAction == 'moveIn'"
              >
                <input
                  type="date"
                  [(ngModel)]="editMoveOutDate"
                  style="width: 120px;"
                />
              </td>
              <td *ngIf="currentAction == 'moveOut'">
                <input
                  type="number"
                  [(ngModel)]="editAskingRentMXN"
                  style="width: 120px;"
                />
              </td>
              <td *ngIf="currentAction == 'moveOut'">
                <input
                  type="number"
                  [(ngModel)]="editAskingRentUSD"
                  style="width: 120px;"
                />
              </td>
            </tr>
            <tr *ngIf="splitSuite" style="height: 50px;">
              <td>
                {{ selectedTenant.floorModule }}
              </td>
              <td>{{ selectedTenant.suiteWarehouse }}-A</td>
              <td>
                {{ selectedTenant.area - newTenantArea | localNumber: "1.2-2" }}
              </td>
              <td>
                <div style="position: relative; text-align: start;">
                  <span> Vacant </span>
                </div>
              </td>
              <td>
                <span></span>
              </td>
              <td>
                <span></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="discardChanges()"
      >
        Discard changes
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!validateSaveButton()"
        (click)="saveChanges()"
      >
        Save
      </button>
      <button type="button" class="btn btn-secondary" (click)="closeModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>
